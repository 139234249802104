import React, { useRef, useEffect, useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Link } from 'gatsby'
import { Fade } from 'react-reveal'

import people from '../../data/people.js'

// const people = [
//     {
//         name: "Alan Roseman",
//         title: "Owner/Advisor",
//         image: alan,
//         bg: "bg-blue-100",
//     },
//     {
//         name: "Chris Langford",
//         title: "Owner/Advisor",
//         image: chris,
//         bg: "bg-green-100",
//     },
//     {
//         name: "Josh Roberts",
//         title: "Owner/Advisor",
//         image: josh,
//         bg: "bg-blue-100",
//     },
//     {
//         name: "Alan Roseman",
//         title: "Owner/Advisor",
//         image: alan,
//         bg: "bg-blue-100",
//     },
//     {
//         name: "Chris Langford",
//         title: "Owner/Advisor",
//         image: chris,
//         bg: "bg-green-100",
//     },
//     {
//         name: "Josh Roberts",
//         title: "Owner/Advisor",
//         image: josh,
//         bg: "bg-red-100",
//     },
//     {
//         name: "Alan Roseman",
//         title: "Owner/Advisor",
//         image: alan,
//         bg: "bg-blue-100",
//     },
//     {
//         name: "Chris Langford",
//         title: "Owner/Advisor",
//         image: chris,
//         bg: "bg-green-100",
//     },
//     {
//         name: "Josh Roberts",
//         title: "Owner/Advisor",
//         image: josh,
//         bg: "bg-red-100",
//     },
// ]

// const outsider = document.getElementById('outsider');
// const distance = 200;

// function scrollLft() {
//     outsider.scrollBy({
//         left: -distance,
//         behavior: 'smooth'
//     });
// }

// function scrollRight() {
//     outsider.scrollBy({
//         left: distance,
//         behavior: 'smooth'
//     });
// }

// Scroll Buttons
// const buttonRight = document.getElementById('slideRight');
// const buttonLeft = document.getElementById('slideLeft');

function scrollRight() {
    document.getElementById('outsider').scrollLeft += 800;
}
function scrollLft() {
    document.getElementById('outsider').scrollLeft -= 800;
}

// Buttons
// var div = document.getElementById('outsider');
// var scrollable = div.scrollWidth > div.clientWidth;

const TeamMini = () => {

    // const outsider = useRef(null)

    // function scrollRight() {
    //     outsider.current.scrollLeft += 800;
    // }
    // function scrollLft() {
    //     outsider.current.scrollLeft -= 800;
    // }

    // useEffect(() => {

    //     scrollLft()
    //     scrollRight()

    // }, [])


    return (
        <section className='py-24 bg-gray-100'>
            <div className="container mx-auto flex flex-row">

                <button onClick={scrollLft} className={`rounded-full flex-none w-12 h-12 bg-blue-500/50 self-center hover:bg-blue-500 hover:text-white cursor-pointer z-10 `}>
                    <ChevronLeftIcon className='h-8 w-8 mx-auto self-center' />
                </button>

                <div id="outsider" className="-ml-4 -mr-4 grow flex flex-row flex-nowrap items-end space-x-2 overflow-auto hide-scrollbar overscroll-x-contain scroll-smooth ">
                    {/* <div ref={outsider} id="outsider" className="-ml-4 -mr-4 grow flex flex-row flex-nowrap items-end space-x-2 overflow-auto hide-scrollbar overscroll-x-contain scroll-smooth "> */}
                    {people.map((person) => (
                        <Link to={`/about#${person.fname} ${person.lname}`}>

                            <div className='z-0'>
                                {/* <div className={`w-72 bg-gray-200 hover:${person.bg} rounded-md`}> */}
                                <div className={`w-72 bg-gray-200 hover:bg-blue-300 rounded-md`}>
                                    <img className='object-cover object-top grayscale hover:grayscale-0 h-96' src={person.image} />
                                </div>
                                {/* <h3 className='pt-4'>{person.fname}</h3>
                                <p className="">{person.title}</p> */}
                            </div>
                        </Link>

                    ))}
                </div>

                <button onClick={scrollRight} className="rounded-full flex-none w-12 h-12 bg-blue-500/50 self-center overflow-visible hover:bg-blue-500 hover:text-white cursor-pointer z-10">
                    <ChevronRightIcon className='h-8 w-8 mx-auto self-center' />
                </button>


            </div>
        </section>
    )
}

export default TeamMini