import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import computer from "../images/computer.jpg"
import Accolades from "../components/sections/Accolades"
import MiniContent from "../components/sections/MiniContent"

import IndexNavbar from "../components/navigation/IndexNavbar"
import Navbar from "../components/navigation/Navbar"
import Footer from "../components/navigation/Footer"

import family from '../images/family.jpeg'
import Button from "../components/elements/Button"
import VideoHero from "../components/sections/VideoHero"
import VideoHero2 from "../components/sections/VideoHero2"
import Plg from "../components/sections/Plg"

import svg from "../images/logos/wave.svg"
import SmallServices2 from "../components/sections/SmallServices2"
import VideoCard from "../components/sections/VideoCard"

import llu from "../images/LLU21.png"
import TeamMini from "../components/sections/TeamMini"
// import Banner from "../components/sections/Banner"

import slugify from "slugify"

const IndexPage = ({ data }) => {

  return (
    <div className="" style={{ color: "#ffffff" }}>
      {/* <div className={` + ${data.directus.Banner.status == 'show' ? 'block' : 'hidden'}`}>
        <Banner />
      </div> */}
      <IndexNavbar />
      {/* <Navbar /> */}
      <Seo title="Home" />
      {/* <VideoHero /> */}
      <VideoHero2 />

      {/* floating video section  */}
      <VideoCard />
      {/* <SmallServices2 /> */}
      <TeamMini />

      <MiniContent />
      <Plg />

      {/* <div className="bg-gray-100 h-96"></div> */}
      <Footer />
    </div >
  )
}

export default IndexPage

// export const query = graphql`
// query {
//   directus {
//     Banner {
//       status
//     }
//   }
// }
// `