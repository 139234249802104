import React from 'react';
import plan from '../../images/logos/plan.png'
import live from '../../images/logos/live.png'
import give from '../../images/logos/give.png'
import { Fade } from 'react-reveal'

const Plg = () => {
    return <div className="bg-gray-100 pt-36 pb-24">
        <div className="container mx-auto px-5">
            <div className="w-full">
                {/* <div className="w-16 border-b-4 border-gray-200 mx-auto mb-4"></div>
                <h2 className="mb-12 text-gold-400 text-center">Plan Live Give</h2> */}
                {/* <p className="mb-12 text-center">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ex expedita vitae sed incidunt id, tempora natus dolore vel asperiores autem.
                </p> */}
            </div>
            <div className="grid lg:grid-cols-3 gap-8">
                {/* <div className="space-y-2 group flex flex-col "><img src={plan} style={{ filter: 'invert(58%) sepia(56%) saturate(5769%) hue-rotate(201deg)' }} className='max-h-10 w-32 group-hover:scale-110 brightness-0 saturate-100' /><h3 className="">Plan well Financially</h3><p className="w-7/8">Do you have a financial plan that will lead to the life you want to live?</p></div>
                <div className="space-y-2 group flex flex-col "><img src={live} style={{ filter: 'invert(58%) sepia(56%) saturate(5769%) hue-rotate(201deg)' }} className='max-h-10 w-32 group-hover:scale-110 brightness-0 saturate-100' /><h3 className="">Live Life Abundantly</h3><p className="w-7/8">Are your finances allowing you to live a full life, or a barrier keeping you from it?</p></div>
                <div className="space-y-2 group flex flex-col "><img src={give} style={{ filter: 'invert(58%) sepia(56%) saturate(5769%) hue-rotate(201deg)' }} className='max-h-10 w-32 group-hover:scale-110 brightness-0 saturate-100' /><h3 className="">Give Lavishly</h3><p className="w-7/8">Are you able to give back and support causes that you believe in?</p></div> */}
                <div className="space-y-2 group flex flex-col "><img src={plan} className='max-h-10 w-32 brightness-0 saturate-100' /><h3 className="">Plan well Financially</h3><p className="w-7/8">Do you have a financial plan that will lead to the life you want to live?</p></div>
                <div className="space-y-2 group flex flex-col "><img src={live} className='max-h-10 w-32 brightness-0 saturate-100' /><h3 className="">Live Life Abundantly</h3><p className="w-7/8">Are your finances allowing you to live a full life, or a barrier keeping you from it?</p></div>
                <div className="space-y-2 group flex flex-col "><img src={give} className='max-h-10 w-32 brightness-0 saturate-100' /><h3 className="">Give Lavishly</h3><p className="w-7/8">Are you able to give back and support causes that you believe in?</p></div>
            </div>
        </div>

    </div>;
};

export default Plg;
