import React from 'react';
import video from '../../images/RWA.mp4'
import Button from '../elements/Button';
import { Fade } from 'react-reveal'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { Link } from 'gatsby';

const VideoHero2 = () => {
    return (
        <ParallaxProvider>

            <div>
                {/* <header class="lg:-mt-24 relative flex items-center justify-center h-screen overflow-hidden" style={{ height: "80vh" }}> */}
                <header class="lg:-mt-24 relative flex items-center justify-center overflow-hidden smheight md:mdheight">
                    <div class="relative z-30 h-full w-full text-2xl text-white bg-blue-500 bg-opacity-90  flex items-center justify-center">
                        {/* <div class="relative z-30 h-full w-full text-2xl text-black bg-white bg-opacity-80  flex items-center justify-center"> */}
                        {/* <Fade bottom duration={1500} distance="10px"> */}
                        {/* <Parallax translateY={[30, -30]} scale={[1, 0.90]} opacity={[100, 0]}> */}
                        <div className="relative z-40 items-center text-center md:-mt-24">
                            <h1 className="text-4xl leading-none md:text-7xl xl:max-w-3xl text-white pt-12">Wealth Has Purpose</h1>
                            {/* <h1 className="text-6xl leading-none md:text-6xl xl:max-w-3xl text-gray-900">Wealth Has Purpose</h1> */}
                            <p className="mx-auto mt-6 mb-8 text-md lg:text-lg sm:mb-12 md:max-w-md lg:max-w-lg text-blue-100 ">Roseman Wealth Advisors exists to help individuals and families realize and fulfill that purpose.</p>
                            {/* <p className="mt-6 mb-8 text-md md:text-lg sm:mb-12 xl:max-w-3xl text-gray-600 ">Roseman Wealth Advisors exists to help individuals <br />and families realize and fulfill that purpose.</p> */}
                            <div className="flex flex-wrap justify-center">
                                {/* <Link to="/what-to-expect" className="rounded mr-2 inline-flex items-center group hover:scale-105 active:scale-100 text-white bg-gold-400 hover:bg-gold-500 active:bg-gold-300 drop-shadow px-8 py-4 text-xl leading-none">What to Expect</Link> */}
                                <Button path="/what-to-expect" style="link-white" size="large">Get in Touch</Button>
                            </div>
                        </div>
                        {/* </Parallax> */}
                        {/* </Fade> */}
                    </div>
                    {/* <video
                autoplay
                loop
                muted
                class="absolute z-10 w-auto min-w-full min-h-full max-w-none"
            >
                <source src={video} type="video/mp4" />

                Your browser does not support the video tag.
            </video> */}
                    <video autoPlay muted loop playsInline id="myVideo" className="absolute h-full w-full z-10 overflow-hidden object-cover filter grayscale contrast-200">
                        {/* <video autoPlay muted loop id="myVideo" className="w-screen mix-blend-overlay overflow-hidden filter grayscale"> */}
                        <source src={video} type="video/mp4" />
                    </video>
                </header>
            </div>
        </ParallaxProvider>
    )
};

export default VideoHero2;
