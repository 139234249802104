import Alan from '../images/team/alan.png'
import Chris from '../images/team/chris.png'
import Josh from '../images/team/josh.png'
import Chandler from '../images/team/chandler.png'
import John from '../images/team/john.png'
import Julie from '../images/team/julie.png'
import Jessica from '../images/team/jessica.png'
import MaryLynne from '../images/team/marylynne.png'
import Nina from '../images/team/nina.png'
import Peter from '../images/team/peter.png'
import Zach from '../images/team/zach.png'
import Billy from '../images/team/billy.png'

const people = [
    {
        image: Alan,
        imageLeft: false,
        author: true,
        fname: 'Alan',
        lname: 'Roseman',
        position: 'Owner/Advisor',
        email: 'alan@rosemanwealth.com',
        certifications: [
            'CPA',
            'CFP',
            'CAP',
        ],
        bio: 'Alan was born and raised in Tyler, is married to a Tylerite (Ashley), and they are now raising their 5 active kids in their hometown. The Roseman’s are members of First Baptist Church – Tyler, graduates of Baylor University, and now enjoy living and working near family and friends. Alan currently serves on the Board of Directors for the East Texas Communities Foundation. He loves coaching Little League baseball, watching sports, and traveling.',
        scheduler: 'https://calendly.com/alanroseman',
    },
    {
        image: Chris,
        imageLeft: true,
        author: false,
        fname: 'Chris',
        lname: 'Langford',
        position: 'Owner/Advisor',
        email: 'chris@rosemanwealth.com',
        certifications: [
            'CHFC',
            'CAP',
        ],
        bio: 'Chris graduated from Texas A&M University. In 1985, he married Julie, also an Aggie, and they have three sons and a daughter…all Aggies. Chris is an active member of Grace Community Church and enjoys exercise, traveling and music, but is especially fond of the outdoors. Chris would consider a great day to be hiking a long trail in the mountains, skiing on fresh snow, or rappelling down a slot canyon.',
        scheduler: 'https://calendly.com/chrislangford-rwa',
    },
    {
        image: Josh,
        imageLeft: false,
        author: true,
        fname: 'Josh',
        lname: 'Roberts',
        position: 'Owner/Advisor',
        email: 'josh@rosemanwealth.com',
        certifications: [
            'CPA',
            'CFP',
        ],
        bio: 'Josh grew up in Tyler and after graduating from Baylor with a degree in financial planning, moved back to Tyler to pursue a master’s degree in accounting at UT Tyler and a career in financial planning. He enjoys all sports and outdoor activities, learning and trying new things, serving in the youth and children’s ministries at South Spring Baptist Church, and is a big fan of Baylor and UT Tyler athletics. He is currently serving on the Bethesda Health Clinic board of directors.',
        scheduler: 'https://calendly.com/joshroberts-rwa',
    },
    {
        image: Peter,
        imageLeft: true,
        author: true,
        fname: 'Peter',
        lname: 'Rae',
        position: 'Investment Analyst',
        email: 'peter@rosemanwealth.com',
        certifications: [
            'CFP',
            'CKA',
        ],
        bio: 'Peter was born in Casper, Wyoming; raised in Laurel, Montana; and hopes to be buried in Tyler, Texas. The smartest thing Peter has ever done is marry his beautiful wife Rachel, who is his superior in every way. Peter and Rachel have seven children and they are members of Good Shepherd Church. Peter graduated from New Saint Andrews College in 2007, and was part owner of an independent RIA prior to joining Roseman Wealth Advisors. The Raes enjoy fellowshipping with their dear friends from church, school, and the greater community. And they try to make trips to visit their extended family in Maryland and Montana whenever they get the chance.',
        scheduler: 'https://calendly.com/peterrae-rwa',
    },
    {
        image: Zach,
        imageLeft: false,
        author: true,
        fname: 'Zach',
        lname: 'Tingle',
        position: 'Associate',
        email: 'zach@rosemanwealth.com',
        certifications: [],
        bio: 'Zach was born and raised in Buena Vista, Colorado. He earned a Bachelor of Arts in English Composition from Texas A&M University, a Master of Arts in Theological Ethics from Fuller Theological Seminary and is currently pursuing a graduate certificate in Financial Planning from the University of Georgia. Prior to Roseman, Zach worked with a fee-only Financial Planning firm in Dallas. He and his wife, Hannah, moved to Tyler to raise their three children in the church community at South Spring Baptist Church. In his free time, Zach enjoys carpentry, boarding gaming, and the occasion home update project.',
        scheduler: 'https://calendly.com/zachtingle',
    },
    {
        image: John,
        imageLeft: true,
        author: true,
        fname: 'John',
        lname: 'Sokol',
        position: 'Senior Investment Strategist',
        email: 'john@rosemanwealth.com',
        certifications: [
            'CFA',
        ],
        bio: 'John Sokol has served our clients since 2006 as our team’s lead investment strategist. John previously served as Chief Investment Officer for multiple wealth management firms and as an institutional equity analyst for a major investment firm. He has participated in the local United Way as both a board member and as Chairperson of the Investment Committee. John has earned an MBA with distinction from Indiana University, a B.S. in Finance from Illinois State University and the prestigious CFA® Charter.',
    },
    {
        image: Chandler,
        imageLeft: false,
        author: true,
        fname: 'Chandler',
        lname: 'Batts',
        position: 'Associate',
        email: 'chandler@rosemanwealth.com',
        certifications: [],
        bio: 'Chandler grew up in the Dallas area, and has lived in Texas his entire life. After graudating from Texas A&M University, his career began in sales, consulting, and recruiting, but his passion has always been in the personal finance and stewardship space. Chandler and his wife Nina moved to Tyler when they were married in 2017, and have fallen in love with the community here in East Texas. Chandler and Nina are active members of South Spring Baptist Church, and Chandler enjoys fitness, spending time with family and friends, being a dad to his son Aiden, and all things “tech.”',
        scheduler: 'https://calendly.com/chandlerbatts',
    },
    {
        image: Julie,
        imageLeft: true,
        author: true,
        fname: 'Julie',
        lname: 'Langford',
        position: 'Marketing and Client Engagement',
        email: 'julie@rosemanwealth.com',
        certifications: [],
        bio: 'Julie graduated from Texas A&M University and worked with IBM as a Systems engineer in Dallas. She is married to Chris Langford and stayed home with their four children before she became the Marketing Assistant for Roseman Wealth Advisors. Julie is an active member of Grace Community Church, is a sustainer with the Junior League of Tyler, and is a member of the Women’s Fund. Her passions include outdoor adventures with Chris, anything involving their kids, and yoga and running.',
    },
    {
        image: Jessica,
        imageLeft: false,
        author: true,
        fname: 'Jessica',
        lname: 'Bundy',
        position: 'Client Services Coordinator',
        email: 'jessica@rosemanwealth.com',
        certifications: [],
        bio: 'Jessica Bundy was born and raised in Birmingham, AL. She earned her MBA from the University of Montevallo which was where she met her husband, Scott Bundy. In 2015 they moved to Scott’s hometown of Tyler to call home and raise their two children, Stella and Mac. The Bundy’s are members of First Baptist Church – Tyler where Jessica teaches children’s Sunday school class. Jessica’s joys in life are cooking, reading and traveling, especially to the gulf beaches where she vacationed with her family as a child. Her happy place is splashing in their pool at home with her family',
    },
    {
        image: Nina,
        imageLeft: true,
        author: true,
        fname: 'Nina',
        lname: 'Batts',
        position: 'Client Service Administrator',
        email: 'nina@rosemanwealth.com',
        certifications: [],
        bio: 'Nina was born and raised in Northern Illinois. After graduating from Indiana Wesleyan University, she moved to Tyler where she met her husband Chandler during a leadership development program through a local summer camp where she worked during college. Nina and her husband attend South Spring Baptist Church where they serve high school students Sunday mornings and they are involved with the Mentoring Alliance. She enjoys spending time with friends and family, exercising with her husband and her dog, and traveling.',
    },
    {
        image: MaryLynne,
        imageLeft: false,
        author: true,
        fname: 'Mary Lynne',
        lname: 'Jones',
        position: 'Client Service Administrator',
        email: 'marylynne@rosemanwealth.com',
        certifications: [],
        bio: 'Mary Lynne is a native Texan. She has lived in Nebraska, Oklahoma, Iowa and South Carolina, but she came back to Texas as quickly as she could after graduating from Columbia International University in Columbia, South Carolina. She is married to Robbie Jones and was a stay at home mom to their 4 children and 26 foster children for 20 years. Mary Lynne is an active member of Bethel Bible Church, an avid fan of “The Office”, enjoys step aerobics, organizing/decluttering, crocheting and paddleboarding.',
    },
    {
        image: Billy,
        imageLeft: true,
        author: true,
        fname: 'Billy',
        lname: 'Braly',
        position: 'Advisor',
        // email: 'billy@rosemanwealth.com',
        certifications: [
            'CPA',
            'CFP',
        ],
        bio: 'Billy was raised on a farm in Bullard, Texas. He has been married to his wife Myrna for 50 years, and they have two children, Scott and Ashley, and three grandchildren. Billy has been practicing as a CPA for 35 years with a firm he established in Tyler, Texas. For the last 20 years, however, his career has focused on estate and wealth management with an emphasis on financial and tax planning. Billy served on the Board of Directors for the Tyler Boys and Girls Club for 10 years. He and his wife enjoy boating, camping, traveling, and creating memories with friends and family',
    },
]

export default people;