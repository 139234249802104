import React from 'react';
import Button from '../elements/Button';
import {
    XMarkIcon,
} from '@heroicons/react/24/outline'
import {
    PlayIcon,
} from '@heroicons/react/24/solid'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import office from '../../images/office.png'

const VideoCard = () => {

    let [isOpen, setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    return <section className="bg-gray-100">
        {/* medium */}
        <div className="-mt-32 container mx-auto w-full md:w-2/3 lg:w-2/3 2xl:w-1/2 bg-white shadow-md rounded-2xl h-64 z-50 relative md:grid grid-cols-5 border-2 border-gray-100 hidden">
            <div className="rounded-l-2xl row-span-full col-span-3 col-start-1 bg-white md:clipped z-30">
                <div className="p-8 mr-8">
                    <p className="my-4">We are a <span className="font-semibold text-blue-500">team-based comprehensive financial advisory</span> company located in Tyler, Texas, whose professionals work with clients across the country.</p>
                    <button onClick={openModal}>
                        <Button size="mdlink" style="link">Watch Video</Button>
                    </button>

                </div>

            </div>
            <div onClick={openModal} className="row-span-full col-span-3 col-end-6 w-full rounded-r-2xl bg-cover bg-center flex align-middle z-20 cursor-pointer" style={{ backgroundImage: `url(${office})` }}>
                {/* <PlayIcon className="drop-shadow-2xl h-16 w-16 text-blue-500 mx-auto my-auto text-center flex" /> */}
                <div className="rounded-full bg-blue-200 p-2 mx-auto my-auto text-center flex drop-shadow-2xl hover:scale-105 hover:bg-blue-300">
                    <PlayIcon className="drop-shadow-2xl h-8 w-8 text-blue-500 mx-auto my-auto text-center flex" />
                </div>
            </div>

        </div>

        {/* old  */}
        {/* <div className="-mt-32 container mx-auto md:w-2/3 lg:w-2/3 2xl:w-1/2 bg-white shadow-md rounded-2xl h-64 z-50 relative md:flex flex-row border-2 border-gray-100 hidden">
            <div className="rounded-l-2xl basis-1/2">
                <div className="p-8">
                    <p className="my-4">We are a <span className="font-semibold text-blue-500">team-based comprehensive wealth management</span> company located in Tyler, Texas, whose professionals work with clients across the country.</p>
                    <button onClick={openModal}>
                        <Button size="mdlink" style="link">Watch Video</Button>
                    </button>

                </div>
            </div>
            <div className="w-24 bg-gray-100 -skew-x-12 h-full -mr-12 z-10"></div>
            <div className="h-full rounded-r-2xl bg-gray-100 basis-1/2 md:p-8 flex">
                <div onClick={openModal} className="rounded-lg mx-auto w-full max-w-xs h-full bg-cover bg-center flex align-middle hover:scale-105 z-20 cursor-pointer" style={{ backgroundImage: `url(${office})` }}>
                    <PlayIcon className="drop-shadow-2xl h-16 w-16 text-blue-500 mx-auto my-auto text-center flex" />
                </div>
            </div>
        </div> */}
        {/* small down  */}
        <div className="container mx-auto bg-white border-2 border-gray-100 md:hidden">
            <div className="">
                <div className="p-8 flex flex-col text-center items-center">
                    <div onClick={openModal} className="rounded-lg w-64 h-36 bg-cover bg-center flex align-middle hover:scale-105 mb-4 cursor-pointer" style={{ backgroundImage: `url(${office})` }}>
                        <div className="rounded-full bg-blue-200 p-2 mx-auto my-auto">
                            <PlayIcon className="drop-shadow-2xl h-8 w-8 text-blue-500 mx-auto my-auto text-center flex" />
                        </div>
                        {/* <img className="rounded-lg h-full text-right grow z-20" src="https://picsum.photos/600/400" /> */}
                    </div>
                    <p className="my-4">We are a <span className="font-semibold text-blue-500">team-based comprehensive financial advisory</span> company located in Tyler, Texas, whose professionals work with clients across the country.</p>
                    <button onClick={openModal}>
                        <Button size="mdlink" style="link">Watch Video</Button>
                    </button>
                </div>
            </div>
            {/* <div className="h-full rounded-r-2xl bg-gray-100 basis-1/2 lg:p-8 flex">

                <div className="rounded-lg w-full h-full bg-cover bg-center flex align-middle hover:scale-105 z-20" style={{ backgroundImage: "url(https://images.unsplash.com/photo-1445699269025-bcc2c8f3faee?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80)" }}>
                    <PlayIcon className="drop-shadow-2xl h-16 w-16 text-blue-500 mx-auto my-auto text-center flex" />
                </div>
            </div> */}
        </div>

        {/* Modal  */}
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-auto"
                onClose={closeModal}
            >
                <div className="min-h-screen px-4 text-center bg-black/80">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="z-50 max-w-full inline-block p-6 my-8 overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">
                            <div className="flex flex-row justify-between mb-4">

                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                </Dialog.Title>
                                <div className="">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                        onClick={closeModal}
                                    >
                                        <XMarkIcon className='h-8 w-8' />
                                    </button>
                                </div>
                            </div>

                            <div className="rounded-md">
                                {/* <iframe id="ytplayer" type="text/html" width="640" height="360" */}
                                <iframe id="ytplayer" type="text/html" width="960" height="540"
                                    src="https://www.youtube.com/embed/Nu3kHII0rNk?autoplay=1&origin=http://example.com&modestbranding=1&controls=0"
                                    frameborder="0"
                                    className="rounded-md max-w-full"
                                ></iframe>
                            </div>


                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    </section >;
};

export default VideoCard;
